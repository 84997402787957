<template>
  <div class="d-flex flex-column px-5 px-md-10 pt-8">
    <div class="pt-9">
      <h2>{{ $t('project.competition.matchLocations.title') }}</h2>
      <div class="my-12">
        <v-icon color="primary" size="24" v-text="'lightbulb_outline'" />
        {{ $t('project.competition.matchLocations.disclaimer') }}
      </div>
      <div v-if="matchLocations.length">
        <div class="d-flex justify-end">
          <v-text-field
            v-model="search"
            class="flex-grow-0 ml-3"
            :label="$t('common.search')" prepend-inner-icon="search"
            tag="span" outlined dense
          />
        </div>
        <v-data-table
          :headers="headers" :items="matchLocations" :search="search"
          :sort-by="['createdAt']" :sort-desc="true" dense class="mt-6 table--has-pointer"
        >
          <template #[`item.fields`]="{ item }">
            <div class="d-flex justify-space-between align-center">
              <multiple-labels :labels="item.fields" />
              <v-menu>
                <template #activator="{ on, attrs }">
                  <v-btn color="primary" icon v-bind="attrs" v-on="on" @click.prevent @mousedown.stop>
                    <v-icon v-text="'more_vert'" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="modify(item.id)">
                    <v-list-item-title v-text="$t('common.modify')" />
                  </v-list-item>
                  <v-list-item @click="deleteMatchLocation(item.id)">
                    <v-list-item-title v-text="$t('common.delete')" />
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </div>
      <v-btn color="primary" outlined class="mb-2" @click="matchLocationId = null; showEditMatchLocationDialog = true">
        <v-icon left v-text="'add_box'" /> {{ $t('common.add') }}
      </v-btn>
    </div>
    <edit-match-location-dialog
      v-if="showEditMatchLocationDialog" :organization-id="organizationId" :project-id="projectId"
      :match-location-id="matchLocationId" @close-dialog="showEditMatchLocationDialog=false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MatchLocations',
  components: {
    EditMatchLocationDialog: () => import('@/modules/competition/EditMatchLocationDialog'),
  },
  props: {
    organizationId: { type: String, default: null },
    projectId: { type: String, default: null },
  },
  data: () => ({ search: null, showEditMatchLocationDialog: false, matchLocationId: null }),
  computed: {
    ...mapGetters({ rawMatchLocations: 'competition/matchLocations' }),
    headers() {
      return [
        { text: this.$t('common.name'), value: 'name' },
        { text: this.$t('common.address'), value: 'address' },
        { text: this.$t('common.field'), value: 'fields' },
      ]
    },
    matchLocations: ({ rawMatchLocations }) => rawMatchLocations.map(matchLocation => ({
      ...matchLocation,
      fields: matchLocation.fields?.map(f => `${f.name}: ${f.matchDurationInMinutes}`),
    })),
  },
  methods: {
    async modify(matchLocationId) {
      this.matchLocationId = matchLocationId
      this.showEditMatchLocationDialog = true
    },
    async deleteMatchLocation(matchLocationId) {
      const { organizationId, projectId } = this
      this.$confirm(this.$t('project.competition.matchLocations.confirmDeleteMatchLocation')).then(async res => {
        if (res) await this.runAsync(() => this.$store.dispatch('competition/deleteMatchLocation', { organizationId, projectId, matchLocationId }))
      })
    },
  },
}
</script>
